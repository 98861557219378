import moment, { Moment } from 'moment';

import { Timezone } from '@/components/Timeline/types';
import { SelectedTime } from '@/context/types';

export const isMeetingRoomTimeInPast = (
	timelineDate: Moment,
	selectedTime: SelectedTime,
	isAllDayChecked: boolean,
	timezone: Timezone
) => {
	const timelineWithTimezoneTime = timelineDate.clone().tz(timezone, true);
	const currentOfficeTime = moment().tz(timezone);

	if (isAllDayChecked) {
		return timelineWithTimezoneTime.isSameOrBefore(currentOfficeTime, 'day');
	}

	const fromTimeWithTimezone = selectedTime.from.tz(timezone, true);

	timelineWithTimezoneTime.set({
		hour: fromTimeWithTimezone.hour(),
		minute: fromTimeWithTimezone.minute(),
		second: 0,
	});

	return timelineWithTimezoneTime.isBefore(currentOfficeTime);
};
