import { MeetingRoomReservation } from '@/queries/booking/types';

import { LocationTypeId } from './Configuration';

export enum DeskSize {
	Small = 'small',
	Large = 'large',
}

export interface BookingEntity {
	bookingId: number;
	userId: number;
	firstName: string;
	lastName: string;
	dateList: Array<string>;
	isBlock: boolean;
	workplaceNumber: number;
	workplaceId: number;
	weeksInInterval: number;
}

export interface DeskBooking {
	bookingId: number;
	userId: number;
	dateList: Array<string>;
	firstName: string;
	lastName: string;
	isBlock: boolean;
	workplaceId: number;
	workplaceNumber: number;
	workplaceOfficeId: number;
	workspaceId: number;
	weeksInInterval: number;
}

export interface MeetingRoomBooking {
	id: number;
	weeksInInterval: number;
	isRepetitiveBooking: boolean;
	reservationFromUtc: string;
	reservationToUtc: string;
	reservationPeriodFromOfficeDate: string;
	reservationPeriodToOfficeDate: string;
	reservationFromOfficeTime: string;
	reservationToOfficeTime: string;
	userId: number;
	userFirstName: string;
	userLastName: string;
	meetingRoomId: number;
	reservationEndOfficeDate: string;
	meetingRoomName: string;
	reservations: MeetingRoomReservation[];
}

export interface BookingNotification {
	notificationId: number;
	dateList: string[];
	workplaceNumber: number;
	workplaceId: number;
	workplaceOfficeId: number;
	message: string;
	isBlock: boolean;
}

export interface Workplace {
	workplaceNumber: number;
	workplaceId: number;
	userId: number;
	firstName: string;
	lastName: string;
	department: string;
	isBlocked: boolean;
}

export interface BookingReservation {
	date: string;
	userWorkplaces: Workplace[];
}

export enum MinskSpacesInternalCodes {
	SpaceA = 'MPN186-S403',
	SpaceB = 'MPN186-S404',
	SpaceC = 'MPN186-S405',
	SpaceD = 'MPN186-S406',
}

export interface OfficeSelectorOption {
	name: string;
	officeLocation: LocationTypeId;
}
