import moment from 'moment';

import { BookingContextInitialValues } from '@/context/types';

import { DEFAULT_BOOKING_OFFICE, DEFAULT_EMPLOYEE } from './booking';

export const initialBookingState: BookingContextInitialValues = {
	meetingRoomCollisions: [],
	timelineDate: moment().startOf('day'),
	isAllDayChecked: false,
	isWorkingHours: true,
	selectedTime: null,
	selectedItem: null,
	selectedSpaceId: null,
	firstDay: moment().startOf('day'),
	lastDay: moment().startOf('day'),
	office: DEFAULT_BOOKING_OFFICE,
	initialOffice: null,
	employee: null,
	initialEmployee: DEFAULT_EMPLOYEE,
	error: null,
	highlightedDays: {
		bookedList: [],
		yourList: [],
		blockStartDate: null,
	},
	workplaces: [],
	weeksCount: 0,
	hasWorkplaceCollision: undefined,
	officeWorkplaces: [],
	isTimelineDateSynchronized: false,
};

export enum BookingActions {
	SET_TIMELINE_DATE = 'SET_TIMELINE_DATE',
	SET_ALL_DAY_CHECKED = 'SET_ALL_DAY_CHECKED',
	SET_SELECTED_ITEM = 'SET_SELECTED_ITEM',
	SET_IS_WORKING_HOURS = 'SET_IS_WORKING_HOURS',
	SET_SELECTED_SPACE_ID = 'SET_SELECTED_SPACE_ID',
	SET_FIRST_DAY = 'SET_FIRST_DAY',
	SET_SELECTED_TIME = 'SET_SELECTED_TIME',
	SET_LAST_DAY = 'SET_LAST_DAY',
	SET_EMPLOYEE = 'SET_EMPLOYEE',
	SET_OFFICE = 'SET_OFFICE',
	SET_INITIAL_OFFICE = 'SET_INITIAL_OFFICE',
	SET_HIGHLIGHTED_DAYS = 'SET_HIGHLIGHTED_DAYS',
	SET_WORKPLACES = 'SET_WORKPLACES',
	SET_ERROR = 'SET_ERROR',
	RESET_ON_OFFICE_CHANGE = 'RESET_ON_OFFICE_CHANGE',
	RESET_ON_MEETING_ROOMS_TAB_CHANGE = 'RESET_ON_MEETING_ROOM_TAB_CHANGE',
	INIT = 'INIT',
	SET_WEEKS_COUNT = 'SET_WEEKS_COUNT',
	SET_MEETING_ROOM_COLLISIONS = 'SET_MEETING_ROOM_COLLISIONS',
	SET_HAS_WORKPLACE_COLLISION = 'SET_HAS_WORKPLACE_COLLISION',
	SET_INITIAL_TIMELINE_DATE = 'SET_INITIAL_TIMELINE_DATE',
	SET_OFFICE_WORKPLACES = 'SET_OFFICE_WORKPLACES',
}
