export enum Departments {
	Js = 'Js',
	Java = 'Java',
	Net = 'Net',
	Python = 'Python',
	QA = 'QA',
}

export const isPMO = (department?: string) => {
	if (!department) {
		return false;
	}

	return department === 'PMO';
};

export const DepartmentsExtraOptions = {
	AllProjects: 'All Projects',
};

export const departmentIdsWithCertificates = [
	'Net',
	'Js',
	'Java',
	'Python',
	'QA',
];
