import {
	MeetingRoomReservation,
	MeetingRoomReservationEntry,
} from '@/queries/booking/types';
import { MeetingRoomType } from '@/types/Configuration';

export interface SelectedTimePeriod<T> {
	from: T;
	to: T;
	isOverlapped: boolean;
	isLoading: boolean;
}

export type SelectTimePeriod<T> = (section: Section, from: T, to: T) => void;

export interface BookedDataTime {
	hours: number;
	minutes: number;
}

export interface TimelineProps {
	data: BookedDataResponse[];
	selectedTimePeriod?: SelectedTimePeriod<BookedDataTime> | null;
	onTimePeriodSelection?: SelectTimePeriod<Date>;
	selectedSectionId?: number | null;
	onSectionSelection?: (section: Section) => void;
	isWorkingHoursOnly?: boolean;
	onIsWorkingHoursOnlyChange?: (isWorkingHoursOnly: boolean) => void;
	selectedDate: Date;
	onGoForwardDate: () => void;
	onGoBackwardDate: () => void;
	onGoTodayDate: () => void;
	scrollToSelectedPeriod?: Pick<TimelineScroll, 'behavior'>;
	scrollToCurrentTime?: TimelineScroll;
	selectionPeriod?: number;
	selectFromPeriodStart?: boolean;
	hasCurrentTimeIndicator?: boolean;
	isPeriodSelectionDisabled?: boolean;
	showLoader?: boolean;
	timezone?: TimezoneObject;
	meetingRoomsConfig: Array<MeetingRoomType>;
}

export interface BookedData
	extends Omit<
		MeetingRoomReservationEntry,
		| 'reservationFromUtc'
		| 'reservationToUtc'
		| 'reservationFromOfficeDateTime'
		| 'reservationToOfficeDateTime'
		| 'isMeetingRoomEntryRepetitiveBooking'
	> {
	id: number;
	fromDateTime: BookedDataTime;
	toDateTime: BookedDataTime;
	isRepetitive: boolean;
	showSkeleton?: boolean;
}

export interface BookedDataResponse
	extends Omit<MeetingRoomReservation, 'reservations'> {
	reservations: BookedData[];
}

export interface Section {
	meetingRoomId: number;
	meetingRoomName: string;
}

type TimelineScrollBehaviour = 'smooth' | 'auto';
export type TinelineScrollPosition = 'start' | 'center' | 'end';

export interface TimelineScroll {
	behavior: TimelineScrollBehaviour;
	position?: TinelineScrollPosition;
}

export enum Timezone {
	MINSK = 'Europe/Minsk',
	WARSAW = 'Europe/Warsaw',
}

export interface TimezoneObject {
	timezoneDisplayName: string;
	timezoneOffset: string;
	timezone: Timezone;
}
