import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Typography } from '@mms/mms-ui-library';

const FIXED_TIME_WIDTH = 48;

export const FixedTypography = styled(Typography)(
	({ theme }) => css`
		width: ${FIXED_TIME_WIDTH}px;
		padding: 0px ${theme.spaces.xs}px;
		display: inline-block;
	`
);
